import * as Sentry from "@sentry/browser";


declare global {
    interface Window {
        FIRST_ENV: {
            appVersion: string
            userAccountId?: string
            userName?: string
            userEmail?: string
            insecure?: boolean
            isDevelopment?: boolean
            sentryDsn?: string
            env?: string
            serverId?: string
            bootId?: string
        },
        Sentry: typeof Sentry
    }
}

if(window.FIRST_ENV.sentryDsn) {
    Sentry.init({
        dsn: window.FIRST_ENV.sentryDsn,
        release: window.FIRST_ENV.appVersion,
        environment: window.FIRST_ENV.env,
        ignoreErrors: ["[object Object]"],
        // always enable without injection, so any app can have any entrypoint to it
        integrations: [Sentry.feedbackIntegration({
            autoInject: false,
        })]
    });
    if(window.FIRST_ENV.serverId) {
        Sentry.setTag("server_name", window.FIRST_ENV.serverId)
    }
    if(window.FIRST_ENV.bootId) {
        Sentry.setTag("boot_id", window.FIRST_ENV.bootId)
    }
    if(window.FIRST_ENV.userAccountId || window.FIRST_ENV.userEmail || window.FIRST_ENV.userName) {
        Sentry.setUser({
            id: window.FIRST_ENV.userAccountId,
            email: window.FIRST_ENV.userEmail,
            fullName: window.FIRST_ENV.userName
        })
    }
}
// For unbundled parts of the frontend
window.Sentry = Sentry;